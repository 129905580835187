import Select from 'react-select'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { icExportDataState, icIsesState, icSettingsState, icTagsState } from './state'
import * as st8 from './state'
import { useEffect, useRef, useState } from 'react'
import { append, filter, find, identity, map, propEq, test } from 'ramda'
import Uploader from './Uploader'
import './TagInput.scss'
import classNames from 'classnames'
const IC = require('ic-js')

function TagInput ({ className, placeholder, onChange, options, ignoreLowercase, preventUpload }) {
  const icTags = useRecoilValue(icTagsState)
  const icSettings = useRecoilValue(icSettingsState)
  const ises = useRecoilValue(icIsesState)
  const setAddTag = useSetRecoilState(st8.icAddTagState)
  const exportData = useSetRecoilState(icExportDataState)
  const ref = useRef()
  const [didYouMean, setDidYouMean] = useState(false)
  const [value, setValue] = useState('')
  const toLabels = map(obj => ({ label: obj.tag, value: obj.tag }))
  const filteredTags = icTags
    .filter(t => !/^[\/]/.test(t.tag)) 
    .filter(t => {
      const pieces = value.split('')
      return test(new RegExp(value, 'i'), t.tag) || test(new RegExp(pieces.join('.*'), 'i'), t.tag.slice(0, 50))

    })
  let opts = options || toLabels(filteredTags)
  opts.sort((a, b) => {
    if (a.label === value) return -1
    if (b.label === value) return 1
    return a.label.length - b.label.length
  })
  if (didYouMean) {
    const cleanIses = filter(tag => tag.to === didYouMean, ises).map(tag => ({ tag: tag.from.replace(/^:is:/, '') }))
    opts = toLabels(cleanIses)
  }
  if (!find(propEq('value', value), opts) && value !== '') {
    opts.unshift({
      label: didYouMean ? `Yes, I meant "${value}"` : value,
      value
    })
  }
  // console.log(opts);
  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [])
  const wrap = what => {
    if (preventUpload) {
      return what
    }
    return (
      <Uploader 
        onUpload={res => {
          const tagName = `/ipfs/${res.cid}`
          const type = res.file.type
          setAddTag(append({ to: tagName, from: type }))
          if (onChange) {
            onChange(tagName)
          }
        }}
      >
        {what}
      </Uploader>
    )
  }
  console.log('x')
  return (
    <div className='tag-input-wrap relative'>
      {wrap(
        <Select
          options={opts}
          ref={ref}
          value={value}
          autoFocus
          noOptionsMessage={() => ''}
          escapeClearsValue
          classNamePrefix='tag-input'
          className={classNames('tag-input', className, { error: didYouMean })}
          placeholder={placeholder}
          filterOption={identity}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === 'Escape' || e.key === 'Tab') {
              if (value === '' && onChange) {
                onChange('')
              }
              setValue('')
            }
          }}
          onMenuClose={() => {
            if (!onChange) {
              // onChange()
            }
          }}
          onInputChange={origVal => {
            let val = IC.clean(origVal)
            if (icSettings.lowercase && !ignoreLowercase) {
              val = val.toLowerCase()
            }
            setValue(val)
          }}
          onChange={val => {
            if (onChange) {
              onChange(val.value)
            }
            setValue('')
            ref.current.focus()
          }}
        />
      )}
    </div>
  )
}


export default TagInput

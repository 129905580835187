import classNames from 'classnames'
import { map, without, append, filter, find, groupBy, identity, last, mapObjIndexed, not, pipe, prop, propEq, reverse, sort, uniqBy, values, takeLast, reduce, toPairs, flatten } from 'ramda'
import { useState, useEffect } from 'react'
import { useLocation, useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import DbAvatar from './DbAvatar'
import { SORTS, FILTERS, copyToClipboard, icUrl, isImage, seePathForTags, srcForTag, isVideo } from './lib/utils'
import './See.scss'
import { breadcrumbState, icDataState, ipfsState, icAddTagState, icSettingsState, filterIState, sortIState, icExportDataState, pinataJwtState, icConnectedState, isMineFsState } from './state'
import * as st8 from './state'
import TagInput from './TagInput'
import Tag from './Tag'

function See () {
  const params = useParams()
  const history = useHistory()
  const location = useLocation()
  const tagParams = params && params[0] && params[0].split('/').map(decodeURIComponent).sort((a, b) => a.localeCompare(b))
  let tag = tagParams[0] 
  const [breadcrumb, setBreadcrumb] = useRecoilState(breadcrumbState)
  const [pinataJwt, setPinataJwt] = useRecoilState(pinataJwtState)
  const exportData = useSetRecoilState(icExportDataState)
  const setAddTag = useSetRecoilState(icAddTagState)
  const icData = useRecoilValue(icDataState)
  const ipfs = useRecoilValue(ipfsState)
  const icAddress = useRecoilValue(icConnectedState)
  const icSettings = useRecoilValue(icSettingsState)
  const icImages = useRecoilValue(st8.icImagesState)
  const filterI = useRecoilValue(filterIState)
  const sortI = useRecoilValue(sortIState)
  const isMine = useRecoilValue(isMineFsState)
  const [pj, setPj] = useState('')
  const [selectedTags, setSelectedTags] = useRecoilState(st8.selectedTagsState)
  const [cachedHttpLinks, setCachedHttpLinks] = useState({})
  useEffect(async () => {
    setBreadcrumb(pipe(
      without([tag]),
      append(tag),
      // takeLast(3)
    ))
    if (!isVideo(tag) && !icImages.includes(tag) && /https?:\/\//.test(tag) && !cachedHttpLinks[tag]) {
      setCachedHttpLinks(Object.assign({}, cachedHttpLinks, {[tag]: {}}))
      const data = await fetch(`https://productive-ablaze-algebra.glitch.me/?url=${tag}`)
        .then(res => res.json())
        .catch(e => ({}))
      setCachedHttpLinks(Object.assign({}, cachedHttpLinks, {[tag]: data}))
    }
  }, [setBreadcrumb, tag])
  useEffect(() => {
    setSelectedTags([])
  }, [location])
  const countFilterAndSort = pipe(
    mapObjIndexed((uniqTags, tag) => {
      return {
        count: uniqTags.length,
        sum: uniqTags.reduce((acc, val) => val.yesNo === '-' ? acc - 1 : acc + 1, 0),
        yes: uniqTags.filter(t => t.yesNo !== '-').length,
        no: uniqTags.filter(t => t.yesNo === '-').length,
        me: (find(propEq('dId', ipfs.id), uniqTags) || {}).yesNo,
        tag
      }
    }),
    values,
    FILTERS[filterI].func,
    SORTS[sortI].func
  )
  const tags = pipe(
    reduce((acc, val) => {
      if(tagParams.includes(val.to)) {
        const key = val.from + val.dId
        if (!acc[key]) acc[key] = []
        acc[key].push(val)
      }
      return acc
    }, {}),
    toPairs,
    filter(([k, v]) => v.length === tagParams.length),
    map(([k, v]) => v),
    flatten,
    groupBy(prop('from')),
    countFilterAndSort,
    sort((a, b) => {
      if (selectedTags.includes(a.tag)) return -1
      if (selectedTags.includes(b.tag)) return 1
      return 0
    })
  )(icData)
  const groups = pipe(
    reduce((acc, val) => {
      if(tagParams.includes(val.from)) {
        const key = val.to + val.dId
        if (!acc[key]) acc[key] = []
        acc[key].push(val)
      }
      return acc
    }, {}),
    toPairs,
    filter(([k, v]) => v.length === tagParams.length),
    map(([k, v]) => v),
    flatten,
    groupBy(prop('to')),
    countFilterAndSort,
    filter(t => t.sum > 0)
  )(icData)
  const [open, setOpen] = useState(false)
  const [groupsOpen, setGroupsOpen] = useState(false)
  const [goOpen, setGoOpen] = useState(false)
  if (!icAddress) {
    return null
  }
  let desc, img, link 
  let title = tagParams.map(tag => {
    let title
      // image
      if (isVideo(tag)) {
        img = true
        title = (
          <video src={tag} controls>
          </video>
        )
      } else if (icImages.includes(tag)) {
        const src = srcForTag(tag)
        img = true
        title = (
          <div className='image' >
            <img src={src} />
            <a target='_blank' href={src} className='caption'>{tag}</a>
          </div>
        )
      } else if (/https?:\/\//.test(tag)) {
        title = (
          <a href={tag} target='_blank'>{tag}</a>
        )
        if (cachedHttpLinks[tag] && cachedHttpLinks[tag].title) {
          link = true
          img = true
          title = (
            <div className='text-left leading-tight'>
              <a className='p-4 block' href={tag} target='_blank'>{tag}</a>
              {cachedHttpLinks[tag].title && <div className='hint pl-4 text-sm pr-2'>{cachedHttpLinks[tag].title}</div>}
              {cachedHttpLinks[tag].description && <div className='pl-4 font-light pr-2 mt-2 text-sm'>{cachedHttpLinks[tag].description}</div>}
              {cachedHttpLinks[tag].image && <img className='mt-2' src={cachedHttpLinks[tag].image} />}
            </div>
          )
        }
      } else {
        title = tag 
      }
      const newTags = without([tag], tagParams).sort((a, b) => a.localeCompare(b))
      return (
        <span onClick={() => setSelectedTags(append(tag))} key={tag} className={classNames('tag', { img, link })}>
          {title} 
          <Link className='remove' to={tagParams.length === 1 ? '/' : '/c/' + newTags.join('/')}>x</Link>
        </span>
      )
  })
  let ignoreLowercase = false
  const depth1 = icSettings.depth && parseInt(icSettings.depth, 10) === 1

  let hint = ''
  if (icData.length < 20 && tagParams.length === 1 && tags.length === 0 && groups.length === 0) {
    hint = <p className='text-base text-left text-gray-400 '>
      Hint: Nothing will be saved until you add something to "{tagParams[0]}".
    </p>
  }

  return (
    <div className='see p-2'>
      {<div className={classNames('groups-wrap', { open: groupsOpen })}>
        <label> <a className={classNames('plus', { open: groupsOpen } )}onClick={() => setGroupsOpen(not)}>+</a></label>
        {groupsOpen && <TagInput
          placeholder={``}
          ignoreLowercase={ignoreLowercase}
          onChange={to => {
            tagParams.concat(selectedTags).forEach(from => {
              setAddTag(append({
                from,
                to
              }))
            })
            setGroupsOpen(false)
          }}
        />}
        <div className='groups'>
          <ul>
            {groups.map(g => {
              return <li key={`group-${g.tag}`}><Tag tag={g} link /></li>
            })}
          </ul>
        </div>
      </div>}
      <h1 className={classNames({ open: goOpen })}>
        {title} 
        <span className='go-open'>
          <a className='plus always' onClick={() => setGoOpen(not)}>+</a>
          {goOpen && <TagInput
            placeholder={``}
            ignoreLowercase={ignoreLowercase}
            onChange={t => {
              history.push(`/c/${tagParams.concat(t).sort((a, b) => a.localeCompare(b)).map(encodeURIComponent).join('/')}`)
              setGoOpen(false)
            }}
          />}
        </span>
      </h1>
      {desc}
      <div className='add-tag'>
        <a className='plus' onClick={() => setOpen(not)}>+</a>
        {(tags.length < 1 || open) &&
          <TagInput
            placeholder={``}
            ignoreLowercase={ignoreLowercase}
            onChange={from => {
              tagParams.concat(selectedTags).forEach(to => {
                setAddTag(append({
                  to,
                  from
                }))
              })
            }}
          />}
      </div>
      {hint}
      <ul className='tags'>
        {tags.map(t => {
          const selected = selectedTags.includes(t.tag)
          return (
            <li key={t.tag}>
              <Tag
                tag={t}
                className={classNames({ open: selected })}
                link
                selected={selected}
                onSelect={checked => {
                  if (checked) {
                    setSelectedTags(append(t.tag))
                  } else {
                    setSelectedTags(without([t.tag], selectedTags))
                  }
                }}
                onYes={selected && (() => {
                  if (t.me === '+') return
                  tagParams.forEach(to => {
                    selectedTags.forEach(from => {
                      setAddTag(append({
                        to,
                        from,
                        yesNo: '+'
                      }))
                    })
                  })
                  setSelectedTags([])
                })}
                onAdd={selected && (() => {
                  history.push(seePathForTags((tagParams || []).concat(selectedTags)))
                  setSelectedTags([])
                })}
                onNo={selected && (() => {
                  if (t.me === '-') return
                  tagParams.forEach(to => {
                    selectedTags.forEach(from => {
                      setAddTag(append({
                        to,
                        from,
                        yesNo: '-'
                      }))
                    })
                  })
                  setSelectedTags([])
                })}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default See
